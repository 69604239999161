import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Avatar from '../avatar';
import { Container, List } from '../blocks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Container;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Avatar`}</h1>
    <p><img parentName="p" {...{
        "src": "/avatar.png",
        "alt": null
      }}></img></p>
    <h2>{`Variations`}</h2>
    <p>{`Over the years, I've made a few variations for special occassions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      